<template>
  <div v-if="designNotFoundError">
    <ControlsNotFound class="mt-6 mb-2"/>
  </div>
  <div v-else-if="fetchError">
    <ControlsSimpleError class="mb-2" :message="$t('fetch.error')"/>
  </div>
  <div v-else class="relative h-full overflow-hidden bg-grey-200">
    <EditorHeader/>

    <button @click="openFeedbackForm" class="cursor-pointer hidden sm:block transition fixed right-4 xl:right-10 bottom-4 xl:bottom-10 z-30 rounded-full">
       <SvgFeedback class="w-10 h-10"/>
    </button>

    <Transition name="side-grow-in">
      <LazyEditorDesignTool v-if="selectedTool === 'design'"  :selected-item="selectedItem" @closed="currentToolClosed"/>
    </Transition>
     <Transition name="side-grow-in">
      <LazyEditorSettingsTool v-if="selectedTool === 'settings'"  :selected-item="selectedItem" @closed="currentToolClosed"/>
    </Transition>
    <Transition name="side-grow-in">
      <LazyEditorTranscriptTool v-if="selectedTool === 'transcript'"  :selected-item="selectedItem" @closed="currentToolClosed"/>
    </Transition>
    <Transition name="side-grow-in">
      <LazyEditorTextTool v-if="selectedTool === 'text'"  :selected-item="selectedItem" @closed="currentToolClosed"/>
    </Transition>
    <Transition name="side-grow-in">
      <LazyEditorProgressTool v-if="selectedTool === 'progress'" :selected-item="selectedItem" @closed="currentToolClosed"/>
    </Transition>
    <Transition name="side-grow-in">
      <LazyEditorProgressEditTool v-if="selectedTool === 'progress-edit'" :selected-item="selectedItem" @closed="currentToolClosed"/>
    </Transition>
    <Transition name="side-grow-in">
      <LazyEditorWaveformTool v-if="selectedTool === 'waveform'" :selected-item="selectedItem" @closed="currentToolClosed"/>
    </Transition>
    <Transition name="side-grow-in">
      <LazyEditorWaveformEditTool v-if="selectedTool === 'waveform-edit'" :selected-item="selectedItem" @closed="currentToolClosed"/>
    </Transition>
    <Transition name="side-grow-in">
      <LazyEditorShapeTool v-if="selectedTool === 'shape'" :selected-item="selectedItem" @closed="currentToolClosed"/>
    </Transition>
    <Transition name="side-grow-in">
      <LazyEditorShapeEditTool v-if="selectedTool === 'shape-edit'" :selected-item="selectedItem" @closed="currentToolClosed"/>
    </Transition>
     <Transition name="side-grow-in">
      <LazyEditorBackgroundGroupTool v-if="selectedTool === 'background-group'" :selected-item="selectedItem" @closed="currentToolClosed"/>
    </Transition>
     <Transition name="side-grow-in">
      <LazyEditorBackgroundVideoTool v-if="selectedTool === 'background-video'" :selected-item="selectedItem" @closed="currentToolClosed"/>
    </Transition>
    <Transition name="side-grow-in">
      <LazyEditorSubtitlesTool v-if="selectedTool === 'subtitles'" :selected-item="selectedItem" @closed="currentToolClosed"/>
    </Transition>
    <Transition name="side-grow-in">
      <LazyEditorUserVideoTool v-if="selectedTool === 'user-video'" :selected-item="selectedItem" @closed="currentToolClosed"/>
    </Transition>
    <Transition name="side-grow-in">
      <LazyEditorImageTool v-if="selectedTool === 'image'" :selected-item="selectedItem" @closed="currentToolClosed"/>
    </Transition>

    <div v-if="!initialized" class="absolute left-1/2 -ml-[50px] top-1/2 -mt-[140px]">
      <ControlsLoader class="p-0"/>
    </div>

    <ClientOnly>
     <LazyEditorContainer v-if="design && design.video_size" :width="design.video_size.width" :height="design.video_size.height" />
    </ClientOnly>

  </div>
  <LazyDialogNotice :open="noticeDialog"/>
  <LazyDialogFeedback :open="feedbackFormDialog"/>
  <LazyDialogConfirm :open="confirmDialog" />
  <LazyDialogBackgroundVideo />
</template>

<script setup>
import {useEditorStore} from "~/store/editor";
import {useUtilsStore} from "~/store/utils";
import {useAssetStore} from "~/store/asset";
import {useAudioStore} from "~/store/audio";
import {useAudioSegmentStore} from "~/store/audio-segment";
import {storeToRefs} from "pinia";
import {useI18n} from "vue-i18n";
import introJs from "intro.js";
import "intro.js/introjs.css";

definePageMeta({
  layout: "editor",
  middleware: ['auth'],
});

const {t} = useI18n();
const {$sentryCaptureException} = useNuxtApp();
const utilsStore = useUtilsStore();
const assetStore = useAssetStore();
const audioStore = useAudioStore();
const audioSegmentStore = useAudioSegmentStore();

useHead({
  title: t('title.editor'),
});

const {feedbackFormDialog, noticeDialog, confirmDialog} = storeToRefs(utilsStore);
const route = useRoute();
const router = useRouter();
const config = useRuntimeConfig();
const editorStore = useEditorStore();
const { $listen, $toast } = useNuxtApp();

const selectedTool = ref(null);
const selectedItem = ref(null);
const {fonts, design, fetchError, designNotFoundError} = storeToRefs(editorStore);
const {mainAudioVolume, backgroundAudioVolume} = storeToRefs(audioStore);
const {segment, fetchError:segmentFetchError} = storeToRefs(audioSegmentStore);
const initialized = ref(false);

await editorStore.getDesign(route.params.slug, config);
await editorStore.getFonts(config);
await assetStore.fetchUserColors(config);

if (design.value && design.value.video_size) {
   await editorStore.getBackgroundVideoGroups(1, config);
}

if (route.query.clip && route.query.clip.length > 0) {
  await audioSegmentStore.getSegment(route.query.clip, config);
} else if (design.value && design.value.audio_file_segment) {
  // if we have a design and it has an associated segment, load the segment
  await audioSegmentStore.getSegment(design.value.audio_file_segment, config);
  if (!segmentFetchError.value) {
    router.push({path: route.fullPath, query: {clip: design.value.audio_file_segment}});
  }
}
else {
  segment.value = null;
}

if (segmentFetchError.value) {
  $toast.error('segment.fetch.error.design');
}

if (route.query.background_audio) {
  await audioStore.getAudio(route.query.background_audio, config, true);
}
if (route.query.main_audio_volume) {
  mainAudioVolume.value = Number(route.query.main_audio_volume);
}
if (route.query.background_audio_volume) {
  backgroundAudioVolume.value = Number(route.query.background_audio_volume);
}

const openFeedbackForm = () => {
  feedbackFormDialog.value = true;
}

let links = [
  {
    rel: 'preconnect', href: "https://fonts.googleapis.com"
  },
  {
     rel: 'preconnect', href:"https://fonts.gstatic.com", crossorigin: true
  },
]

const seenEditorIntro = useCookie('seen_editor_intro', {
  value: false,
  maxAge: 31536000
});

if (fonts.value) {
  fonts.value.forEach((font) => {
    let url = font.url;
    if (font.is_user_uploaded) {
      url = `${config.public.externalApiBase}/api/fonts/${font.key}/font.css`;
    }
    /*links.push({
      rel: 'preload',
      as: 'style',
      href: url,
    });*/
    links.push({
      rel: 'stylesheet',
      href: url,
    });
  })
}

useHead({
  title: t('title.editor'),
  link: links,
});

const toolSelected = (payload) => {

  selectedTool.value = payload.selectedTool;
  selectedItem.value = payload.selectedItem;
}

$listen('tool_selected', toolSelected);

/** Don't directly create the object */
const toolAdded = (payload) => {

  if (payload === 'text' || payload === 'image') {
    return;
  }

  if (selectedItem.value) {
    if (payload !== selectedItem.value.smsType) {
      selectedItem.value = null;
      selectedTool.value = payload;
      return;
    }

    if (selectedItem.value.smsType === 'progress') {
      selectedTool.value = 'progress-edit';
    }
    else if (selectedItem.value.smsType === 'waveform') {
      selectedTool.value = 'waveform-edit';
    }
    else if (selectedItem.value.smsType === 'shape') {
      selectedTool.value = 'shape-edit';
    }
    else {
      selectedTool.value = payload;
    }
  }
  else {
    selectedTool.value = payload;
  }


}

$listen('tool_added', toolAdded);

const currentToolClosed = () => {
  selectedTool.value = null;

}

$listen('canvas_initialized', () => {

  initialized.value = true;
    if (seenEditorIntro.value) {
      return;
    }
    introJs().setOptions({
      "steps": [
        {
          "title": t('intro.editor.title'),
          "intro": t('intro.editor.description'),
        },
        {
          "intro": t('intro.editor.canvas'),
          "element": document.getElementById('canvas')
        },
        {
          "intro": t('intro.editor.move.tool'),
          "element": document.getElementById('editor.move.tool'),
          "position": "left"
        },
        {
          "intro": t('intro.editor.zoom.tool'),
          "element": document.getElementById('editor.zoom.tool'),
          "position": "top"
        },
        {
          "intro": t('intro.editor.design.tool'),
          "element": document.getElementById('editor.design'),
          "position": "right"
        },
        {
          "intro": t('intro.editor.background.tool'),
          "element": document.getElementById('editor.background'),
          "position": "right"
        },
        {
          "intro": t('intro.editor.image.tool'),
          "element": document.getElementById('editor.image'),
          "position": "right"
        },
        {
          "intro": t('intro.editor.text.tool'),
          "element": document.getElementById('editor.text'),
          "position": "right"
        },
        {
          "intro": t('intro.editor.shapes.tool'),
          "element": document.getElementById('editor.shape'),
          "position": "right"
        },
        {
          "intro": t('intro.editor.waveform.tool'),
          "element": document.getElementById('editor.waveform'),
          "position": "right"
        },
        {
          "intro": t('intro.editor.progress.tool'),
          "element": document.getElementById('editor.progress'),
          "position": "right"
        },
        {
          "intro": t('intro.editor.subtitles.tool'),
          "element": document.getElementById('editor.subtitles'),
          "position": "right"
        },
        {
          "intro": t('intro.editor.delete.tool'),
          "element": document.getElementById('editor.delete.selected'),
          "position": "bottom"
        },
        {
          "intro": t('intro.editor.save.tool'),
          "element": document.getElementById('editor.save'),
          "position": "left-bottom"
        }
      ]
    }).start();
    seenEditorIntro.value = true;
})

</script>

<style scoped>
.grow-in-enter-active,.grow-in-leave-active,
.side-grow-in-enter-active,
.side-grow-in-leave-active{
  transition: all 0.3s ease-in-out;
}

.grow-in-enter-from,
.grow-in-leave-to {
  height: 0;
  opacity: 0;
}

.side-grow-in-enter-from,
.side-grow-in-leave-to {
  width: 0;
  opacity: 0;
}
</style>