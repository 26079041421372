<template>
  <DialogBase class="w-full max-w-2xl text-dark-900" :open="upgradeDialog" @close="closeDialog">
    <h2 class="font-bold font-poppins text-h4s md:text-h4m mb-2">
      {{ t('need.more.video.minutes')}}
    </h2>
    <p v-if="user && user.info && user.info.plan" class="text-sm">
      {{ t('need.more.video.minutes.error.has.plan') }}
    </p>
    <p v-else class="text-sm">
      {{ t('need.more.video.minutes.error') }}
    </p>
    <div class="mt-2 grid gap-4 md:grid-cols-2">
      <div class="h-full">
        <h3 class="font-bold text-base">{{ t('top.up.video.minutes')}}</h3>
        <div v-if="credit_packages.length > 0">
          <p class="text-sm text-grey-500 mb-4">{{ t('purchase.credits.message') }}</p>
          <div class="">
            <div v-for="credit_package in credit_packages" :key="credit_package.id" class="rounded-s border border-grey-400 flex justify-between items-center gap-2 px-3 py-2 text-sm mb-2">
              <span><span class="font-bold">{{ getFirstWord($t(credit_package.t_name + '.short')) }}</span> {{getRemainingWords($t(credit_package.t_name + '.short'))}}</span>
              <button @click="purchasePackage(credit_package.id)" class="w-20 px-4 py-2 bg-brand-500 text-white font-bold rounded-s">
                ${{ credit_package.price }}
              </button>
            </div>
          </div>
          <p class="text-sm text-grey-500">{{ t('purchase.credits.note') }}</p>
        </div>
      </div>
      <div class="h-full">
        <h3 class="font-bold text-base">{{ t('upgrade.plan')}}</h3>
        <p class="text-sm text-grey-500 mb-6 md:mb-4">{{ t('upgrade.plan.message') }}</p>
        <div class="grow md:h-[calc(100%-80px)] flex justify-center items-center rounded-s md:border md:border-grey-400 ">
        <NuxtLink :to="localePath('/account') + '#plans'" target="_blank" class="px-6 py-2 rounded-s bg-brand-500 text-white font-bold">
          {{ t('view.plans') }}
        </NuxtLink>
        </div>
      </div>
    </div>
  </DialogBase>
</template>

<script setup>
  import {useUtilsStore} from "~/store/utils";
  import {useCookieStore} from "~/store/cookie";
  import {useCreditStore} from "~/store/credit";
  import {storeToRefs} from "pinia";

  const config = useRuntimeConfig();
  const { $toast } = useNuxtApp();
  const {t} = useI18n({
    useScope: 'local'
  });

  const utilsStore = useUtilsStore();

  const cookieStore = useCookieStore();
  const creditStore = useCreditStore();

  const {user} = storeToRefs(cookieStore);
  const {upgradeDialog, upgradeDialogType} = storeToRefs(utilsStore);
  const {credit_packages, fetchError, stripeUrl} = storeToRefs(creditStore);

  await creditStore.getCreditPackages(upgradeDialogType.value, config);

  const getFirstWord = (str) => {
    if (!str) return '';
    return str.split(' ')[0];
  }

  const getRemainingWords = (str) => {
    if (!str) return '';
    return str.split(' ').slice(1).join(' ');
  }

  const purchasePackage = async (id) => {
    await creditStore.createPurchaseSession(id, config);
    if (fetchError.value) {
      $toast.error(t('error.something.went.wrong'));
    } else {
      if (stripeUrl.value) {
        await navigateTo(stripeUrl.value, {external: true});
      }
    }
  }

  const closeDialog = () => {
    upgradeDialog.value = false;
  }
</script>

<i18n lang="json">
{
  "en": {
    "need.more.video.minutes": "Need more video minutes?",
    "need.more.video.minutes.error": "You need more video minutes to continue. Purchase more minutes or upgrade your plan.",
    "need.more.video.minutes.error.has.plan": "You need more video minutes to continue. Purchase more minutes below.",
    "top.up.video.minutes": "Top up video minutes",
    "purchase.credits.message": "Purchase credits to use as you go.",
    "purchase.credits.note": "Credits are non-refundable and do not expire. You’ll be directed to Stripe to complete your purchase.",
    "view.plans": "View plans",
    "upgrade.plan": "Upgrade plan",
    "upgrade.plan.message": "Get more video minutes and other features.",
  },
  "de": {

  },
  "fr": {

  },
  "es": {

  },
  "ja": {

  }
}
</i18n>

<style scoped>
dialog {
  min-height: 440px;
  @media (max-width: 640px) {
    min-height: 600px;
  }
}
</style>