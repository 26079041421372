<template>
  <div class="absolute top-0 left-0 w-full h-18 py-4 px-4 md:px-6 flex items-center bg-white text-dark-900 z-40">
    <div class="flex flex-row flex-grow w-1/3">
       <ControlsTooltip position="bottom" bg="dark" size="xs">
        <template #tipped>
          <button @click="toggleShowMenu" class="flex items-center gap-2">
            <img src="~/assets/img/logo-notext.svg" alt="Logo" class="h-8" />
            <SvgChevronDown class="w-4 h-4 text-dark-900"/>
          </button>
        </template>
        <template #default>
           <span>{{t('menu')}}</span>
        </template>
       </ControlsTooltip>

      <Transition name="menu">
      <div v-if="showMenu" :aria-expanded="showMenu" class="absolute left-2 top-14 w-48 h-[calc(100vh-60px)]">
          <LazyNavigationSidebarContent  :stay-open="true" />
      </div>
      </Transition>

      <NuxtLink :to="localePath('/design/mine')" class="flex font-bold p-1 bg-brand-light-1 rounded-s cursor-pointer ml-4 mr-4" :title=" $t('back')">
        <SvgChevronLeft class="w-6 h-6 pointer-events-none" />
      </NuxtLink>
      <ControlsEditableTitle :name="designName" button_title="edit.design.name" @name_submitted="nameUpdated" class="hidden xl:flex" />
    </div>
    <div class="flex-grow flex justify-end items-center gap-2 xl:gap-4 w-1/3">
      <button
          class="flex flex-col"
          @click="deleteSelected"
          id="editor.delete.selected"
          :title="$t('delete.selected.item')"
      >
        <SvgTrash class="w-6 h-6"
            :class="{'text-grey-500': !itemSelected, 'text-dark-900': itemSelected}"
        />
      </button>
      <button class="flex flex-col " @click="undo" id="editor.undo" :title="$t('undo.history')">
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 24 24"
             fill="currentColor"
             class="w-5 h-5"
            :class="{'fill-grey-500': !canUndo, 'fill-dark-900': canUndo}">
        >
          <path fill-rule="evenodd" d="M9.53 2.47a.75.75 0 010 1.06L4.81 8.25H15a6.75 6.75 0 010 13.5h-3a.75.75 0 010-1.5h3a5.25 5.25 0 100-10.5H4.81l4.72 4.72a.75.75 0 11-1.06 1.06l-6-6a.75.75 0 010-1.06l6-6a.75.75 0 011.06 0z" clip-rule="evenodd" />
        </svg>
      </button>
      <button class="flex flex-col" @click="redo" id="editor.redo" :title="$t('redo.history')">
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 24 24"
             fill="currentColor"
             class="w-5 h-5"
            :class="{'fill-grey-500': !canRedo, 'fill-dark-900': canRedo}">
        >
          <path fill-rule="evenodd" d="M14.47 2.47a.75.75 0 011.06 0l6 6a.75.75 0 010 1.06l-6 6a.75.75 0 11-1.06-1.06l4.72-4.72H9a5.25 5.25 0 100 10.5h3a.75.75 0 010 1.5H9a6.75 6.75 0 010-13.5h10.19l-4.72-4.72a.75.75 0 010-1.06z" clip-rule="evenodd" />
        </svg>
      </button>
      <div class="relative z-20 group">
      <button @click="save" id="editor.save" class="py-2 bg-white border border-grey-500 text-dark-900 rounded font-bold text-sm"
        :class="{'px-8': savingDesign && ! exporting, 'pl-4 pr-2': !savingDesign || exporting}"
      >
        <svg v-if="savingDesign && ! exporting" class="animate-spin h-5 w-5 mx-auto stroke-dark-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
        </svg>
        <span v-else class="flex items-center gap-1">
          <SvgBookmarkAdd class="w-5 h-5" />
         {{ $t('save') }}
          <SvgSelectDown class="w-4 h-4" />
        </span>
      </button>
      <div v-if="!savingDesign"
           class="hidden hover:flex group-hover:flex absolute top-[39px] w-48"
          :class="{'left-0': clip || segment, '-left-8': !clip && !segment}"
      >
        <button @click="saveAndExit" class="whitespace-nowrap bg-white text-sm font-bold py-2 shadow-lg pl-4 pr-2 gap-1 flex items-center">
          {{ $t('save.and.exit')}}
          <SvgChevronRight class="w-4 h-4"/>
        </button>
      </div>
      </div>
      <button v-if="clip || segment" @click="saveAndExport" id="editor.save" class="py-2 bg-brand-500 text-white rounded font-bold text-sm"
        :class="{'px-8': savingDesign && exporting, 'px-5': !savingDesign || exporting}"
      >
        <svg v-if="savingDesign && exporting" class="animate-spin h-5 w-5 mx-auto stroke-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
        </svg>
        <span v-else class="flex items-center gap-2">
         {{ $t('export') }}
        </span>
      </button>
    </div>
  </div>
  <DialogUpgradeDialog />
</template>

<script setup>
import {useEditorStore} from "~/store/editor";
import {useAudioSegmentStore} from "~/store/audio-segment";
import {useAudioStore} from "~/store/audio";
import {useAuthStore} from "~/store/auth";
import {useUtilsStore} from "~/store/utils";
import {storeToRefs} from "pinia";
import {useLocalePath} from "#i18n";
import Button from "~/components/color/button.vue";

const localePath = useLocalePath()
const route = useRoute();
const config = useRuntimeConfig();
const { $event, $listen, $toast } = useNuxtApp();

const editorStore = useEditorStore();
const audioStore = useAudioStore();
const audioSegmentStore = useAudioSegmentStore();
const authStore = useAuthStore();
const utilsStore = useUtilsStore();

const {t} = useI18n();

const clip = ref(route.query.clip || null);
const submitting = ref(false);
const showMenu = ref(false);

const {design} = storeToRefs(editorStore);
const {segment} = storeToRefs(audioSegmentStore);
const {backgroundAudio, mainAudioVolume, backgroundAudioVolume} = storeToRefs(audioStore);
const {upgradeDialog, upgradeDialogType} = storeToRefs(utilsStore);
const user = ref(null);

let url_segment = null;
if (segment.value || clip.value) {
  url_segment = clip.value;
  if (segment.value) {
    url_segment = segment.value.key;
  }
}

const canUndo = ref(false);
const canRedo = ref(false);
const designName = ref(design.value.name);
const exporting = ref(false);
const { savingDesign } = storeToRefs(editorStore);

const itemSelected = ref(false);

const toggleShowMenu = () => {
  showMenu.value = !showMenu.value;
}

const nameUpdated = async (newName) => {
  if (submitting.value) {
    return;
  }

  submitting.value = true;
  await editorStore.updateDesignDetails(design.value.key, newName, config);
  submitting.value = false;
  if (editorStore.fetchError) {
    designName.value = design.value.name;
    $toast.error(t('error.something.went.wrong'));
  } else {
    designName.value = newName;
    design.value.name = designName.value;
    $toast.success(t('update.design.success'));
  }
}

const deleteSelected = () => {
  $event('delete_selected');
}

$listen('tool_selected', (payload) => {
  itemSelected.value = payload.selectedItem !== null;
});

$listen('history_append', (payload) => {
  canUndo.value = payload.canUndo;
  canRedo.value = payload.canRedo;
});

const undo = () => {
  $event('history_undo');
}

const redo = () => {
  $event('history_redo');
}

const doSave = (exit_url) => {
  if (savingDesign.value) {
    return;
  }
  savingDesign.value = true;
  setTimeout(() => {
    $event('save_canvas', exit_url);
  }, 100) // give ui time to update (nextTick doesn't work here)
}

const save = () => {
  doSave(null);
}

const saveAndExit = () => {
  doSave(localePath('/design/mine'));
}

const getExportUrl = () => {
  let url = localePath(`/clip/confirm-${url_segment}`) + `?design=${design.value.key}`;
  if (backgroundAudio.value) {
    url += `&background_audio=${backgroundAudio.value.key}&main_audio_volume=${mainAudioVolume.value}&background_audio_volume=${backgroundAudioVolume.value}`;
  }
  return url;
}

const validSegmentLength = computed(() => {
  if (user.value && user.value.info){
    const duration = segment.value.original_end - segment.value.original_start;
    return user.value.info.video_minutes_remaining === -1 || duration < 60 * user.value.info.video_minutes_remaining;
  }

  return false;
})

const saveAndExport = async () => {

  user.value = await authStore.userDetailsNoSave(config);
  if (! validSegmentLength.value) {
    upgradeDialogType.value = 'video';
    upgradeDialog.value = true;
    return;
  }

  exporting.value = true;
  if (savingDesign.value) {
    return;
  }
  savingDesign.value = true;
  setTimeout(() => {
    $event('save_canvas', getExportUrl());
  }, 100) // give ui time to update (nextTick doesn't work here)
}

onBeforeUnmount(() => {
  exporting.value = false;
})

</script>

<style scoped>
  .menu-enter-active,
  .menu-leave-active {
   max-height: calc(100vh - 60px);
  transition: max-height 0.3s;
  }

.menu-enter-from,
.menu-leave-to {
  max-height: 0;
  overflow:hidden;


}
</style>